<script setup lang="ts">
import { ref, computed } from 'vue';

const isOpen = ref(false);
const iconClass = computed(() =>
  isOpen.value ? 'fa-chevron-up' : 'fa-chevron-down',
);
const emits = defineEmits(['toggleCollapse']);

const toggleCollapse = () => {
  isOpen.value = !isOpen.value;
  emits('toggleCollapse', isOpen);
};
</script>
<template>
  <div class="collapse-details" @click="toggleCollapse">
    <span class="collapse-details-title">
      <slot></slot>
    </span>
    <font-awesome-icon
      class="collapse-details-icon"
      size="2xs"
      :icon="['fas', iconClass]"
    />
  </div>
</template>

<style>
.collapse-details {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.collapse-details-icon {
  height: 1rem;
  margin-left: 0.5rem;
}
</style>
