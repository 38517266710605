// import { createWebHashHistory, createRouter } from 'vue-router'
import { createI18n } from 'vue-i18n';
// import { createPinia } from 'pinia'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import { library } from '@fortawesome/fontawesome-svg-core';
import VueMatomo from 'vue-matomo';
// import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import en from '@/locales/en.json';
import de from '@/locales/de.json';
import type { App } from 'vue';

export const pluginsWrapper = {
  install(GivenVue: App) {
    const Vue = GivenVue;
    const language = navigator.language || navigator.languages[0];

    const i18n = createI18n({
      locale: language.startsWith('de') ? 'de' : 'en',
      fallbackLocale: 'en',
      messages: { en, de },
      legacy: false,
    });

    library.add(
      faPlay,
      faArrowLeft,
      faArrowRight,
      faXmark,
      faChevronUp,
      faChevronDown,
    );
    Vue.component('font-awesome-icon', FontAwesomeIcon);
    Vue.use(i18n);
    Vue.use(VueMatomo, {
      requireConsent: false,
      host: 'https://log.streamdiver.com',
      trackerUrl: 'https://log.streamdiver.com/js/tracker.php',
      debug: true,
      enableLinkTracking: true,
      trackInitialView: true,
      disableCookies: false,
      requireCookieConsent: false,
      enableHeartBeatTimer: true,
      heartBeatTimerInterval: 15,
    });
  },
};
