<script setup lang="ts">
import {
  computed,
  ref,
  watch,
  onMounted,
  onBeforeUnmount,
  type PropType,
  type Ref,
} from 'vue';
import type { Chapter, MediaData } from '@/types/media';
import dayjs from 'dayjs';

const props = defineProps({
  detailsOpen: {
    type: Object as PropType<Ref<boolean>>,
    required: true,
  },
  mediaDetails: {
    type: Object as PropType<MediaData>,
  },
  chapters: {
    type: Object as PropType<Chapter>,
  },
});

const detailsEl = ref<HTMLElement>();
const maxHeight = ref('0');
const isDetailsOpen = computed(() => props.detailsOpen?.value);
let resizeObserver: ResizeObserver | null = null;

const emits = defineEmits(['timeSelect']);

const formatTime = (milliseconds: number) => {
  const time = dayjs.duration(milliseconds, 'milliseconds');
  return `${time.minutes()}:${time.seconds().toString().padStart(2, '0')}`;
};

const updateMaxHeight = () => {
  maxHeight.value = isDetailsOpen.value
    ? `${detailsEl.value?.scrollHeight}px`
    : '0';
};

onMounted(() => {
  resizeObserver = new ResizeObserver(() => updateMaxHeight());
  if (detailsEl.value) {
    resizeObserver.observe(detailsEl.value);
  }
});

onBeforeUnmount(() => {
  if (resizeObserver) {
    resizeObserver.disconnect();
    resizeObserver = null;
  }
});

watch(isDetailsOpen, updateMaxHeight);
</script>

<template>
  <div
    :class="[
      'sd-extended-media-widget-description',
      { 'sd-extended-media-widget--active': detailsOpen },
    ]"
    ref="detailsEl"
  >
    <div
      class="sd-extended-media-widget-description-text"
      v-if="mediaDetails?.description"
      v-html="mediaDetails?.description"
    />
    <div v-if="chapters" class="sd-extended-media-widget-chapters">
      <div
        v-for="chapter in chapters?.entries"
        :key="chapter.id"
        @click="emits('timeSelect', chapter.section.start)"
        class="sd-extended-media-widget-chapter"
      >
        <span class="chapter-time">
          {{ formatTime(chapter.section.start) }}
        </span>
        <h2 class="chapter-title">{{ chapter.section.title }}</h2>
      </div>
    </div>
  </div>
</template>

<style>
.sd-extended-media-widget-description {
  background: rgb(245, 245, 245);
  position: relative;
  z-index: 5;
  transition: max-height 0.3s ease-in-out;
  font-size: 0.875rem;
  color: #4a5568;
  font-weight: 600;
  overflow: hidden;
  padding: 0;
  max-height: 0;
}

.sd-extended-media-widget-description.sd-extended-media-widget--active {
  max-height: v-bind(maxHeight);
}

.sd-extended-media-widget-chapter {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.25rem 1rem;
  cursor: pointer;
}

.chapter-time {
  text-decoration: underline;
  font-size: 0.875rem;
  font-weight: 400;
}

.chapter-title {
  color: #4a5568;
  margin: 0;
  font-size: 0.875rem;
  font-weight: 400;
}

.sd-extended-media-widget-description-text {
  margin: 0 1rem 1rem;
  font-weight: 400;
}

.sd-extended-media-widget-description-text a {
  text-decoration: underline;
}

.sd-extended-media-widget-description-text h1,
.sd-extended-media-widget-description-text h2,
.sd-extended-media-widget-description-text h3,
.sd-extended-media-widget-description-text h4,
.sd-extended-media-widget-description-text h5,
.sd-extended-media-widget-description-text h6 {
  font-weight: initial;
  font-size: initial;
}

.sd-extended-media-widget-chapters {
  margin-bottom: 1rem;
}
</style>
