<template>
  <div
    class="sd-player-widget-wrapper"
    :version="packageVersion"
    :id="assetId"
    :style="{ width, height, aspectRatio }"
    ref="playerWidget"
  >
    <div class="error" v-if="unauthorized">
      <div class="message">
        <p class="title">Media asset unavailable</p>
        <p class="text">This item is configured for private access only.</p>
      </div>
    </div>
    <div class="error" v-else-if="errored">
      <div class="message">
        <p class="title">Media asset not found</p>
        <p class="text">This item does not seem to exist.</p>
      </div>
    </div>
    <div class="sd-player-widget-video" v-else-if="!isImage">
      <video
        ref="videoHolder"
        class="video-js"
        playsinline
        :data-matomo-title="assetId"
        :title="assetId"
        :alt="assetId"
        :poster="posterImageObjectUrl"
      ></video>
    </div>
    <div v-else class="sd-player-widget-image">
      <img
        ref="logo"
        style="position: absolute; right: 1rem; top: 1rem; width: 40px"
      />
      <img ref="image" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { version as packageVersion } from '../package.json';
import {
  ref,
  reactive,
  onMounted,
  onUnmounted,
  computed,
  type PropType,
} from 'vue';
import videojs from 'video.js';
import '@sd/sd-player-plugin';
import { toRaw } from 'vue';
// Load the VideoJS font into the global scope to address the lack of browser
// support for @font-face within the Shadow DOM: https://issues.chromium.org/issues/41085401.
// All other styles are loaded within the shadow root, see `<style>` further below.
import 'videojs-font/css/videojs-icons.css';
import type { Asset } from '@/types/media.ts';
import type { PlayerDesign, TenantSettings } from '@/types/player-config.ts';

type VideoJsPlayerOptions = typeof videojs.options;
type VideoJsPlayer = typeof videojs.players;

const props = defineProps({
  // Experimental attributes (for testing/debugging purposes; may change)
  experimentalApiUrl: String,
  experimentalPublicProxyUrl: String,
  // Stable attributes (the public API of the widget):
  playerSettings: {
    type: Object as PropType<TenantSettings>,
  },
  tenantName: {
    type: String,
    default: null,
  },
  assetId: {
    type: String,
    default: null,
  },
  channelId: {
    type: String,
    default: null,
  },
  accessToken: {
    type: String,
    default: null,
  },
  width: {
    type: String,
    default: null,
  },
  height: {
    type: String,
    default: null,
  },
  aspectRatio: {
    type: String,
    default: '16 / 9',
  },
  disablePoster: {
    type: Boolean,
    default: false,
  },
  seekTime: {
    type: Number,
  },
  autoplay: {
    type: Boolean,
    default: false,
  },
  muted: {
    type: Boolean,
    default: false,
  },
  loop: {
    type: Boolean,
    defalt: false,
  },
  disableControls: {
    type: Boolean,
    default: false,
  },
  quickStart: {
    type: Boolean,
    default: false,
  },
  maxStartupBitrate: {
    type: Number,
    default: 3260000,
  },
});

const apiConfig = reactive({
  url: import.meta.env.VITE_API_URL,
  publicProxyUrl: import.meta.env.VITE_PUBLIC_PROXY_URL,
  accessToken: null,
});
const player = ref<VideoJsPlayer>();
const asset = ref<Asset>();
const settings = reactive({ playerDesign: {} as PlayerDesign });
const errored = ref(false);
const unauthorized = ref(false);
const posterImageObjectUrl = ref<string>();
const videoHolder = ref<HTMLVideoElement | null>(null);
const logo = ref<HTMLImageElement | null>(null);
const image = ref<HTMLImageElement | null>(null);
const playerWidget = ref<HTMLDivElement | null>(null);
const language = navigator.language || navigator.languages[0];

const rendition = computed(
  () =>
    asset.value?.renditions.find(
      (r) => r.type === 'videostream' && r.mimeType === 'application/x-mpegURL',
    )?.source,
);

const isImage = computed(() => asset.value && asset.value.type === 'image');

const seekTimeValid = computed(
  () => !isImage.value && props.seekTime !== undefined && props.seekTime >= 0,
);

const handleAndParseErrors = (response: Response) => {
  if (response.status === 401) {
    unauthorized.value = true;
    throw Error(response.statusText);
  }
  if (!response.ok) {
    errored.value = true;
    throw Error(response.statusText);
  }
  return response;
};

const fetchToken = async () => {
  const resp = await fetch(
    `${apiConfig.publicProxyUrl}/token/${props.tenantName}`,
  );
  handleAndParseErrors(resp);
  const data = await resp.json();
  return data.accessToken;
};

const fetchWithAuth = async (url: string) => {
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${apiConfig.accessToken}`,
    },
  });
  return handleAndParseErrors(response);
};

const fetchJsonWithAuth = async (url: string) => {
  const response = await fetchWithAuth(url);
  return response.json();
};

const fetchBlobWithAuth = async (url: string) => {
  const response = await fetchWithAuth(url);
  return response.blob();
};

const fetchAsset = async () => {
  const assetUrl = `${apiConfig.url}/media/${props.assetId}`;
  const response = await fetchJsonWithAuth(assetUrl);
  asset.value = response.data;
  const event = new CustomEvent('assetLoaded', {
    detail: asset.value,
    bubbles: true,
    composed: true,
  });
  playerWidget.value?.dispatchEvent(event);

  settings.playerDesign = props.playerSettings
    ? props.playerSettings
    : await fetchPlayerDesign();

  initializeContent();
  await fetchTenantSettings();
};

const fetchTenantSettings = async () => {
  const tenantSettingsUrl = `${apiConfig.url}/tenants/current/settings`;
  const response = await fetchJsonWithAuth(tenantSettingsUrl);
  if (window) {
    window._paq?.push(['setSiteId', response.data?.matomoSiteId]);
    window._paq?.push(['MediaAnalytics::setPingInterval', 10]);
    window._paq?.push(['MediaAnalytics::scanForMedia', playerWidget.value]);
  }
};

const fetchPlayerDesign = async () => {
  if (props.channelId) {
    const channelUrl = `${apiConfig.url}/channels/${props.channelId}`;
    const response = await fetchJsonWithAuth(channelUrl);
    return response.data.playerDesign.playerDesign;
  } else {
    const tenantDesignsUrl = `${apiConfig.url}/tenants/current/designs`;
    const response = await fetchJsonWithAuth(tenantDesignsUrl);
    return response.data.playerDesign;
  }
};

const fetchImage = async (url: string) => {
  if (!url) {
    return;
  }
  const imageBlob = await fetchBlobWithAuth(url);
  const imageObjectURL = URL.createObjectURL(imageBlob);
  const imgEl = image.value;
  if (imgEl) {
    imgEl.src = imageObjectURL;
    imgEl.onload = () => URL.revokeObjectURL(imageObjectURL);
  }
};

const fetchPoster = async (url: string) => {
  const blob = await fetchBlobWithAuth(url);
  posterImageObjectUrl.value = URL.createObjectURL(blob);
};

const fetchOverlayPlayerLogo = async () => {
  const { overlayPlayerLogoUrl: logoUrl, showOverlayPlayerLogo: showLogo } =
    settings.playerDesign;
  if (!showLogo || !logoUrl) {
    return;
  }
  const blob = await fetchBlobWithAuth(logoUrl);
  const objectUrl = URL.createObjectURL(blob);
  const logoEl = logo.value;
  if (logoEl) {
    logoEl.src = objectUrl;
    logoEl.onload = () => URL.revokeObjectURL(objectUrl);
  }
};

const initPlayer = () => {
  const showControls = !props.disableControls;

  const playerOptions: VideoJsPlayerOptions = {
    autoplay: props.autoplay,
    controls: showControls,
    muted: props.muted,
    loop: props.loop,
    preload: 'auto',
    controlBar: {
      volumePanel: { inline: false },
    },
    sources: [
      {
        src: rendition.value,
        type: 'application/x-mpegurl',
      },
    ],
    html5: {
      nativeAudioTracks: false,
      nativeVideoTracks: false,
      vhs: {
        overrideNative: false,
        bandwidth: props.maxStartupBitrate,
        limitRenditionByPlayerDimensions: false,
        useDevicePixelRatio: true,
        enableLowInitialPlaylist: props.quickStart,
      },
      hls: {
        overrideNative: false,
        bandwidth: props.maxStartupBitrate,
        limitRenditionByPlayerDimensions: false,
        useDevicePixelRatio: true,
        enableLowInitialPlaylist: props.quickStart,
      },
    },
  };

  const pluginLang = language.includes('de') ? 'de' : 'en';
  const pluginOptions = {
    playerSettings: toRaw({ ...settings.playerDesign, language: pluginLang }),
    widgetId: props.assetId,
    token: `Bearer ${apiConfig.accessToken}`,
  };

  videojs.hookOnce('setup', (player: VideoJsPlayer) => {
    // The sd-videojs-plugin always overwrites and enables the `controls` player
    // option, so we need to skip loading the plugin when controls are disabled.
    // This is fine because the plugin is all about controls and interaction,
    // which are expected to be disabled when controls are disabled.
    if (showControls) {
      player.sdPlugin(pluginOptions);
    }
  });

  player.value = videojs(videoHolder.value!, playerOptions);

  if (player.value && seekTimeValid.value) {
    player.value.currentTime(props.seekTime);
  }

  const event = new CustomEvent('playerLoaded', {
    detail: { player: player.value },
    bubbles: true,
    composed: true,
  });
  playerWidget.value?.dispatchEvent(event);

  if (posterImageObjectUrl.value) {
    URL.revokeObjectURL(posterImageObjectUrl.value);
  }
};

const initializeContent = () => {
  if (isImage.value) {
    fetchOverlayPlayerLogo();
    fetchImage(asset.value?.renditions[0].source as string);
  } else {
    initPlayer();

    if (!props.disablePoster && asset.value?.thumbnail?.url) {
      fetchPoster(asset.value.thumbnail.url);
    }
  }
};

const load = async () => {
  apiConfig.accessToken = props.accessToken || (await fetchToken());
  await fetchAsset();
};

onMounted(() => {
  if (props.experimentalApiUrl) {
    apiConfig.url = props.experimentalApiUrl;
  }
  if (props.experimentalPublicProxyUrl) {
    apiConfig.publicProxyUrl = props.experimentalPublicProxyUrl;
  }
  load();
});

onUnmounted(() => {
  if (player.value) {
    player.value.dispose();
  }
});
</script>

<style>
@import 'video.js/dist/video-js.css';
@import '@sd/sd-player-plugin/dist/sd-player-plugin.css';

.sd-player-widget-wrapper,
.sd-player-widget-video,
.video-js {
  @apply w-full h-full;
}

.video-js.vjs-sd-plugin {
  box-shadow: none;
}

.sd-player-widget-image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @apply w-full h-full overflow-hidden;
}

.error {
  @apply text-neutral-50 bg-neutral-500 flex items-center justify-center p-2 aspect-video;
}

.error .message .title {
  @apply capitalize;
}

.error .message .text {
  @apply text-sm;
}
</style>
