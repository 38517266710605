import type { Payload } from '@/types/api.ts';

export const request = async <T>(
  path: string,
  payload?: Payload,
  options: RequestInit = {},
): Promise<{ status: string; data: T }> => {
  try {
    const response = await fetch(
      `${payload?.baseUrl ?? import.meta.env.VITE_API_URL}/${path}`,
      {
        method: options.method ?? 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(payload?.accessToken && {
            Authorization: 'Bearer ' + payload.accessToken,
          }),
          ...options.headers,
        },
        ...options,
      },
    );

    if (!response.ok) {
      const errorMessage = `HTTP error! Status: ${response.status}`;
      console.error(errorMessage);
      throw new Error(errorMessage);
    }

    return await response.json();
  } catch (error) {
    console.error('Request failed:', error);
    throw error;
  }
};
