<script setup lang="ts">
import {
  ref,
  computed,
  type PropType,
  onMounted,
  onUnmounted,
  watch,
} from 'vue';
import Collapse from '@/components/Collapse.vue';
import type { TenantSettings } from '@/types/player-config';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useI18n } from 'vue-i18n';
import {
  fetchToken,
  fetchMediaChapters,
  fetchMediaChapter,
} from '@/helpers/API';
import type { Asset, MediaData, Chapter } from '@/types/media';
import MediaDetails from '@/components/Media/MediaDetails.vue';
import videojs from 'video.js';

type VideoJsPlayer = typeof videojs.players;

const {
  assetId,
  tenantName,
  height,
  width,
  accessToken: initialAccessToken,
} = defineProps({
  assetId: {
    type: String,
    required: true,
  },
  tenantName: {
    type: String,
    required: true,
  },
  playerSettings: {
    type: Object as PropType<TenantSettings>,
  },
  channelId: {
    type: String,
  },
  accessToken: {
    type: String,
  },
  width: {
    type: String,
    default: '100%',
  },
  height: {
    type: String,
  },
});

dayjs.extend(duration);
dayjs.extend(localizedFormat);

const data = ref<MediaData>();
const playerWidget = ref();
const { t } = useI18n();
const chapters = ref<Chapter>();
const detailsOpen = ref();
const accessToken = ref(initialAccessToken);
const isImage = computed(() => data.value && data.value.type === 'image');
const player = ref();

const formattedLength = computed(() => {
  if (!data.value?.length) {
    return '';
  }
  const durationObj = dayjs.duration(data.value.length);

  if (durationObj.hours()) {
    return dayjs.duration(data.value.length).format('H:mm:ss');
  }
  return dayjs.duration(data.value.length).format('m:ss');
});

const formattedPublishDate = computed(() => {
  if (!data.value?.publishDate) return '';
  return dayjs(data.value.publishDate).format('L');
});

const handleAssetLoad = (asset: Asset) => {
  data.value = {
    id: asset.id,
    title: asset.name,
    length: asset.file.durationInMilliseconds,
    publishDate: asset.audit.createdAtUtc,
    views: asset.usage.views,
    type: asset.type,
    description: asset.description,
  };
};

const timeSeekSelect = (selectedTime: number) => {
  if (
    !isImage.value &&
    selectedTime !== undefined &&
    selectedTime >= 0 &&
    player
  ) {
    player.value.currentTime(selectedTime / 1000);
    player.value.play();
  }
};

const getToken = async () => {
  const res = await fetchToken(tenantName);
  accessToken.value = res.accessToken;
};

const getChapters = async () => {
  if (accessToken.value) {
    const res = await fetchMediaChapters({
      id: assetId,
      accessToken: accessToken.value,
    });
    if (res.data.length) {
      const chapterDetails = await fetchMediaChapter({
        id: assetId,
        accessToken: accessToken.value,
        chapterId: res.data[0].id,
      });
      chapters.value = chapterDetails.data;
    }
  }
};

const handleCollapse = (isOpen: boolean) => (detailsOpen.value = isOpen);

watch(
  accessToken,
  (token) => {
    if (token) {
      getChapters();
    } else {
      getToken();
    }
  },
  { immediate: true },
);

onMounted(() => {
  const handleAssetEvent = (event: CustomEvent<Asset>) => {
    handleAssetLoad(event.detail);
  };
  const handlePlayerEvent = (event: CustomEvent<{ player: VideoJsPlayer }>) => {
    player.value = event.detail.player;
  };

  playerWidget.value?.addEventListener('assetLoaded', handleAssetEvent);
  playerWidget.value?.addEventListener('playerLoaded', handlePlayerEvent);
  onUnmounted(() => {
    playerWidget.value?.removeEventListener('assetLoaded', handleAssetEvent);
    playerWidget.value?.removeEventListener('playerLoaded', handlePlayerEvent);
  });
});
</script>

<template>
  <div class="sd-extended-media-widget-container" :style="{ width }">
    <sd-player-widget
      :style="{ width, height }"
      ref="playerWidget"
      class="sd-extended-media-widget-player"
      :accessToken="accessToken"
      :tenantName="tenantName"
      :assetId="assetId"
      :width="width"
      :height="height"
      :playerSettings="playerSettings"
      :channelId="channelId"
    />
    <div class="sd-extended-media-widget-meta" v-if="data">
      <div class="sd-extended-media-widget-details">
        <div class="sd-extended-media-widget-info">
          <span> {{ formattedPublishDate }}, </span>
          <span class="sd-extended-media-widget-length" v-if="formattedLength">
            {{ formattedLength }},
          </span>
          <span> {{ data.views }} {{ t('common.views') }} </span>
        </div>
        <Collapse
          v-if="chapters || data.description"
          @toggleCollapse="handleCollapse"
        >
          {{ t('common.moreAbout') }}
          {{ t(`common.${data.type}`) }}
        </Collapse>
      </div>
      <h2 class="sd-extended-media-widget-title">
        <span>{{ t(`common.${data.type}`) }}:</span>
        {{ data.title }}
      </h2>
    </div>
    <MediaDetails
      :chapters="chapters"
      :media-details="data"
      :details-open="detailsOpen"
      @time-select="timeSeekSelect"
    />
  </div>
</template>

<style>
.sd-extended-media-widget-container {
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
}

.sd-extended-media-widget-meta {
  padding: 1rem;
  background-color: rgb(245, 245, 245);
}

.sd-extended-media-widget-details {
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  color: #4a5568;
}

.sd-extended-media-widget-info {
  display: flex;
  gap: 0.2rem;
}

.sd-extended-media-widget-title {
  font-size: 1.25rem;
  font-weight: 300;
  margin: 0.5rem 0 0;
}
</style>
