<script setup lang="ts">
import SdPlayerWidget from '@/SdPlayerWidget.ce.vue';
import ListItemCompact from '@/components/ListItem/ListItemCompact.vue';
import { type PropType, ref, watch } from 'vue';
import type { Media } from '@/types/media.ts';
import type { TenantSettings } from '@/types/player-config.ts';
import type { ListItemProps } from '@/composables/useListItem.ts';

const props = defineProps({
  focusVideo: {
    type: Object as PropType<ListItemProps | null>,
  },
  playerSettings: {
    type: Object as PropType<TenantSettings>,
  },
  tenantName: {
    type: String,
    required: true,
  },
  mediaList: {
    type: Object as PropType<Media[]>,
  },
  accessToken: {
    type: String,
  },
  showModalVideo: {
    type: Boolean,
  },
});
const isVisible = ref(false);
const emit = defineEmits(['closeModal', 'focusChange']);

const handleCloseModal = () => (isVisible.value = false);

const handleAfterLeave = () => emit('closeModal');

watch(
  () => props.focusVideo,
  (newValue) => {
    isVisible.value = !!newValue;
  },
);
</script>

<template>
  <transition name="fade" @after-leave="handleAfterLeave">
    <div class="modal" v-show="isVisible">
      <div class="modal_content">
        <div class="modal_content-button-row">
          <button class="w-4 h-4" @click="handleCloseModal">
            <font-awesome-icon
              class="text-lg text-white"
              size="lg"
              :icon="['fas', 'arrow-left']"
            />
          </button>
          <h2 class="modal_title">
            {{ focusVideo?.name }}
          </h2>
        </div>
        <div class="modal_content-player">
          <sd-player-widget
            v-if="focusVideo && showModalVideo"
            :access-token="accessToken"
            :player-settings="playerSettings"
            :tenant-name="tenantName"
            width="100%"
            height="100%"
            :asset-id="focusVideo.id"
          />
        </div>
        <div
          class="modal_content-asset-list box-border w-full"
          v-if="mediaList?.length"
        >
          <list-item-compact
            @focusItem="emit('focusChange', $event)"
            v-bind="media"
            :key="media.id"
            v-for="media in mediaList"
            :accessToken="accessToken"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped>
.modal {
  @apply fixed inset-0 bg-black bg-opacity-50 z-[10000] flex justify-center;

  .modal_content {
    @apply bg-white flex flex-col items-center w-full h-full relative;

    &-button-row {
      @apply w-full flex gap-4 bg-black items-center text-white px-4 py-2 min-h-11;
    }

    &-player {
      @apply w-full relative overflow-hidden h-[250px];
      background: #000;
    }

    &-asset-list {
      @apply overflow-y-auto flex-1;
    }
  }

  .modal_title {
    @apply text-xl truncate;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
