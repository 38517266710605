<script setup lang="ts">
import {
  ref,
  onMounted,
  onBeforeMount,
  watch,
  computed,
  onBeforeUnmount,
} from 'vue';
import ListItem from '@/components/ListItem/ListItem.vue';
import {
  fetchToken,
  getChannelDetails,
  getChannelMediaList,
  getTenantSettings,
} from '@/helpers/API';
import Modal from '@/components/Modal.vue';
import ModalMobile from '@/components/ModalMobile.vue';
import type { ChannelDetails, Media } from '@/types/media.ts';
import type { CenterDesign, TenantSettings } from '@/types/player-config.ts';
import type { ListItemProps } from '@/composables/useListItem.ts';

const props = defineProps({
  tenantName: {
    type: String,
    default: 'dev-internal',
  },
  channelId: {
    type: String,
    default: '2526ae4c-c58c-4268-807c-a9c1237ed72e',
  },
});

const focusVideo = ref<ListItemProps | null>(null);
const channelMedia = ref<Media[][]>();
const playerSettings = ref<TenantSettings>();
const channelDetails = ref<ChannelDetails>();
const page = ref(0);
const listEl = ref<Element | null>(null);
const accessToken = ref<string>();
const useNumberedPage = ref<boolean>(false);
const unchangedData = ref<Media[]>();
const compactModal = ref<boolean>(false);
const observer = ref<ResizeObserver | null>(null);
const showModalVideo = ref<boolean>(true);
const uid = Math.random().toString();

const channelMediaPage = computed(() => {
  if (channelMedia.value) {
    return channelMedia.value[page.value];
  } else {
    return null;
  }
});

onBeforeMount(async () => {
  const getToken = await fetchToken(props.tenantName);
  if (getToken?.accessToken) {
    accessToken.value = getToken.accessToken;
    if (accessToken.value) {
      const getSettings = await getTenantSettings({
        accessToken: accessToken.value,
      });
      if (getSettings) {
        playerSettings.value = getSettings.data;
        updateFont(getSettings.data.centerDesign);
      }
      const channelData = await getChannelDetails({
        id: props.channelId,
        accessToken: accessToken.value,
      });
      channelDetails.value = channelData.data;
    }
  }
});
watch(channelMedia, (newVal, oldVal) => {
  if (newVal?.length !== oldVal?.length) {
    page.value = 0;
  }
});

onMounted(() => {
  observer.value = new ResizeObserver(() => {
    if (
      accessToken.value &&
      channelMedia.value?.length &&
      listEl.value &&
      unchangedData.value
    ) {
      const clientWidth = listEl.value.clientWidth;
      if (clientWidth > 1420) {
        channelMedia.value = paginate(unchangedData.value, 5);
      }
      if (clientWidth > 1150 && clientWidth < 1420) {
        channelMedia.value = paginate(unchangedData.value, 4);
      }
      if (clientWidth > 880 && clientWidth < 1150) {
        channelMedia.value = paginate(unchangedData.value, 3);
        useNumberedPage.value = true;
      }
      if (clientWidth > 600 && clientWidth < 880) {
        channelMedia.value = paginate(unchangedData.value, 2);
        useNumberedPage.value = true;
      }
      if (clientWidth > 200 && clientWidth < 600) {
        channelMedia.value = paginate(unchangedData.value, 1);
        useNumberedPage.value = true;
      }
      if (document.body.clientWidth < 800) {
        compactModal.value = true;
      } else {
        compactModal.value = false;
      }
    }
  });
  if (listEl.value) {
    observer.value.observe(listEl.value);
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        focusVideo.value = null;
      }
    });
  }
});
onBeforeUnmount(() => {
  if (listEl.value) {
    observer.value?.unobserve(listEl.value);
  }
  document.removeEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      focusVideo.value = null;
    }
  });
});
watch(
  accessToken,
  async (newVal) => {
    if (newVal) {
      const res = await getChannelMediaList({
        id: props.channelId,
        accessToken: newVal,
      });
      const data: Media[] = res?.data || [];
      if (listEl.value) {
        const clientWidth = listEl.value.clientWidth;
        unchangedData.value = data;
        if (clientWidth > 1420) {
          channelMedia.value = paginate(data, 5);
        }
        if (clientWidth > 1150 && clientWidth < 1420) {
          channelMedia.value = paginate(data, 4);
        }
        if (clientWidth > 880 && clientWidth < 1150) {
          channelMedia.value = paginate(data, 3);
          useNumberedPage.value = false;
        }
        if (clientWidth > 600 && clientWidth < 880) {
          channelMedia.value = paginate(data, 2);
          useNumberedPage.value = true;
        }
        if (clientWidth > 200 && clientWidth < 600) {
          channelMedia.value = paginate(data, 1);
          useNumberedPage.value = true;
        }
        if (clientWidth < 800) {
          compactModal.value = true;
        }
      }
    }
  },
  { immediate: true },
);

function paginate(arr: Media[], size: number) {
  return arr?.reduce((acc: Media[][], val, i) => {
    const idx = Math.floor(i / size);
    const page = acc[idx] || (acc[idx] = []);
    page.push(val);

    return acc;
  }, []);
}

function handlePage(number: number) {
  if (channelMedia.value) {
    if (number < 0) {
      page.value = channelMedia.value.length - 1; // or atleast last
    } else {
      if (number > channelMedia.value.length - 1) {
        page.value = 0;
      } else {
        page.value = number;
      }
    }
  }
}

function updateFont(centerSettings: CenterDesign) {
  const design = centerSettings;

  if (design && design.fontUrl != null) {
    let style = document.getElementById('center-design-fonts');
    let timeout = 0;
    if (!style) {
      style = document.createElement('style');
      style.setAttribute('id', 'center-design-fonts');
      document.head.append(style);
    } else {
      style.textContent = '';
      style.innerHTML += ' ';
      timeout = 1000;
    }

    setTimeout(() => {
      style.textContent = `
        @font-face {
          font-family: 'Euclid Square';
          src: url('${design.fontUrl} ');
          font-weight: bold;
          font-style: normal;
        }

        @font-face {
          font-family: 'Euclid Square';
          src: url('${design.fontUrl}');
          font-weight: normal;
          font-style: normal;
        }

        @font-face {
          font-family: 'Euclid Square';
          src: url('${design.fontUrl}');
          font-weight: 600;
          font-style: normal;
        }

        @font-face {
          font-family: 'Euclid Square';
          src: url('${design.fontUrl}');
          font-weight: 300;
          font-style: italic;
        }

        @font-face {
          font-family: 'Euclid Square';
          src: url('${design.fontUrl}');
          font-weight: 500;
          font-style: italic;
        }

        @font-face {
          font-family: 'Euclid Square';
          src: url('${design.fontUrl}');
          font-weight: 500;
          font-style: normal;
        }

        @font-face {
          font-family: 'Euclid Square';
          src: url('${design.fontUrl}');
          font-weight: normal;
          font-style: italic;
        }

        @font-face {
          font-family: 'Euclid Square';
          src: url('${design.fontUrl}');
          font-weight: 300;
          font-style: normal;
        }

        @font-face {
          font-family: 'Euclid Square';
          src: url('${design.fontUrl}');
          font-weight: 600;
          font-style: italic;
        }

        @font-face {
          font-family: 'Euclid Square';
          src: url('${design.fontUrl}');
          font-weight: bold;
          font-style: italic;
        }
      `;
      style.innerHTML += ' ';
    }, timeout);
  }
}

function handleFocusModalVideo(video: ListItemProps) {
  if (video) {
    focusVideo.value = video;
    showModalVideo.value = false;
    setTimeout(() => {
      showModalVideo.value = true;
    }, 300);
  }
}
</script>

<template>
  <div class="sd-channels-carousel" :id="uid" version="0.0.1" ref="listEl">
    <h2 class="sd-channels-carousel_title" v-if="channelDetails">
      {{ channelDetails.name }}
    </h2>
    <Modal
      v-if="!compactModal"
      @close-modal="focusVideo = null"
      :focusVideo="focusVideo"
      :tenantName="tenantName"
      :mediaList="unchangedData"
      :access-token="accessToken"
      :player-settings="playerSettings"
      @focusChange="handleFocusModalVideo"
    />
    <ModalMobile
      v-else
      @closeModal="focusVideo = null"
      :focusVideo="focusVideo"
      :tenantName="tenantName"
      :mediaList="unchangedData"
      :access-token="accessToken"
      :player-settings="playerSettings"
      @focusChange="handleFocusModalVideo"
      :showModalVideo="showModalVideo"
    />
    <!--  <transition-group tag="ul" name="slide" class="asset-list" mode="in-out" v-if="channelMediaPage">-->
    <div class="asset-list">
      <list-item
        @focusItem="handleFocusModalVideo($event)"
        v-bind="media"
        :key="media.id"
        v-for="media in channelMediaPage"
        :accessToken="accessToken"
      />
    </div>
    <!--  </transition-group>-->
    <!--  </transition-group>-->
    <div class="w-full flex items-center justify-center">
      <div class="navigation-row" v-if="channelDetails && channelMedia">
        <div>
          <button
            :disabled="channelMedia.length === 1"
            class="w-5 h-5"
            @click="handlePage(page - 1)"
          >
            <font-awesome-icon
              class="text-sm duration-300 ease-in transition-colors"
              :class="
                channelMedia.length > 1 ? 'text-black' : 'text-neutral-200'
              "
              size="lg"
              :icon="['fas', 'arrow-left']"
            />
          </button>
        </div>
        <div class="flex items-center gap-2" v-if="!useNumberedPage">
          <button
            class="w-2 h-2"
            :class="index === page ? 'bg-black' : 'bg-neutral-200'"
            :key="`button-${index}`"
            v-for="(_, index) in channelMedia"
            @click="handlePage(index)"
          ></button>
        </div>
        <div v-else>
          <span>
            {{ page + 1 }}
          </span>
          /
          <span>{{ channelMedia?.length }}</span>
        </div>
        <div>
          <button
            :disabled="channelMedia.length === 1"
            class="w-5 h-5"
            @click="handlePage(page + 1)"
          >
            <font-awesome-icon
              class="text-sm duration-300 ease-in transition-colors"
              :class="
                channelMedia.length > 1 ? 'text-black' : 'text-neutral-200'
              "
              size="lg"
              :icon="['fas', 'arrow-right']"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.sd-channels-carousel {
  font-family: 'Euclid Square';
  @apply bg-neutral-100 py-10 px-6;

  .sd-channels-carousel_title {
    @apply text-center text-2xl;
  }

  .asset-list {
    @apply flex justify-center gap-6 w-full relative;
  }

  .navigation-row {
    @apply flex justify-between items-center max-w-[1348px] flex-grow;
  }
}

.slide-leave-active,
.slide-enter-active {
  transition: all 400ms;
}

.slide-enter {
  transform: translate(1000px, 0);
}

.slide-leave-to {
  transform: translate(-1000px, 0);
}

.slideback-leave-active,
.slideback-enter-active {
  transition: all 400ms;
}

.slideback-enter {
  transform: translate(-100%, 0);
}

.slideback-leave-to {
  transform: translate(100%, 0);
}
</style>
