import { request } from '@/helpers/index.ts';
import type {
  ChannelDetails,
  Chapter,
  ChapterBase,
  Media,
} from '@/types/media.ts';
import type { TenantSettings } from '@/types/player-config.ts';

export async function fetchToken(tenantName: string) {
  try {
    const query = await fetch(
      `${import.meta.env.VITE_PUBLIC_PROXY_URL}/token/${tenantName}`,
      {
        method: 'get',
      },
    );
    const data = await query.json();
    return data;
  } catch (e) {
    console.error(e);
  }
}

export async function getChannelDetails({
  id,
  accessToken,
}: {
  id: string;
  accessToken: string;
}) {
  return await request<ChannelDetails>(`channels/${id}`, { accessToken });
}

export async function getChannelMediaList({
  id,
  accessToken,
}: {
  id: string;
  accessToken: string;
}) {
  return await request<Media[]>(
    `channels/${id}/media?${new URLSearchParams({
      perpage: '50',
      sort: 'createdAtUtc:desc',
    })}`,
    { accessToken },
  );
}

export async function getTenantSettings({
  accessToken,
}: {
  accessToken: string;
}) {
  return await request<TenantSettings>('tenants/current/designs', {
    accessToken,
  });
}

export async function getThumbnail({
  url,
  accessToken,
  tenantName,
}: {
  url: string;
  accessToken?: string;
  tenantName?: string;
}) {
  if (url === null) {
    return '';
  }
  const headers = new Headers();
  let getToken = accessToken;
  if (!getToken && tenantName) {
    const getNewToken = await fetchToken(tenantName);
    getToken = getNewToken.accessToken;
  }
  headers.set('Authorization', `Bearer ${getToken}`);
  const getImage = await fetch(url, { headers });
  const getBlob = await getImage.blob();
  return URL.createObjectURL(getBlob);
}

export async function fetchMediaChapters({
  id,
  accessToken,
}: {
  id: string;
  accessToken: string;
}) {
  return await request<ChapterBase[]>(`media/${id}/chapters`, { accessToken });
}

export async function fetchMediaChapter({
  id,
  accessToken,
  chapterId,
}: {
  id: string;
  accessToken: string;
  chapterId: string;
}) {
  return await request<Chapter>(`media/${id}/chapters/${chapterId}`, {
    accessToken,
  });
}
