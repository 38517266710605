<script setup lang="ts">
import { type ListItemProps, useListItem } from '@/composables/useListItem';
import PlayIcon from '@/public/playericon.png';

const props = defineProps<ListItemProps>();
const emit = defineEmits<(event: 'focusItem', item: ListItemProps) => void>();

const { imgSrc, imgEl, handleFocus, millisecondsToMinutesAndSeconds } =
  useListItem(props, emit);
</script>

<template>
  <div class="asset-list-item" @click="handleFocus(props)">
    <div class="thumbnail-wrapper">
      <div class="relative items-center flex justify-center">
        <div class="w-10 absolute">
          <img class="w-10" :src="PlayIcon" alt="play" />
        </div>
        <img :src="imgSrc" class="thumbnail" ref="imgEl" alt="thumbnail" />
      </div>
    </div>
    <div class="text-content">
      <div class="text-details">
        <span v-if="file?.durationInMilliseconds">
          {{ millisecondsToMinutesAndSeconds(file.durationInMilliseconds) }}
        </span>
      </div>
      <h5 class="list-item-title">{{ name }}</h5>
    </div>
  </div>
</template>

<style scoped>
.asset-list-item {
  padding: 1rem;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  display: flex;
  gap: 1rem;

  .thumbnail-wrapper {
    flex: 0 1 250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .thumbnail {
    height: auto;
  }

  .text-details {
    margin-bottom: 0.5rem;
    font-size: 0.75rem;
    font-weight: 600;
  }

  .text-content {
    flex: 1;
    padding: 0.5rem 0;
  }

  .list-item-title {
    font-size: 1rem;
    line-clamp: 3;
    word-break: break-word;
  }
}
</style>
