<script setup lang="ts">
import { onBeforeMount, onMounted, ref } from 'vue';
import { getThumbnail } from '@/helpers/API';
import PlayIcon from '@/public/playericon.png';

const props = defineProps({
  id: {
    type: String,
  },
  accessToken: {
    type: String,
  },
  tenantName: {
    type: String,
  },
  name: {
    type: String,
    default: '',
  },
  audit: {
    type: Object,
    default: () => {},
  },
  thumbnail: {
    type: Object,
    default: () => {},
  },
  file: {
    type: Object,
    default: () => {},
  },
  status: {
    type: String,
  },
  type: {
    type: String,
  },
  usage: {
    type: Object,
  },
});
const emit = defineEmits(['focusItem']);
const imgSrc = ref<string>('');
const imgEl = ref<HTMLImageElement>();

function handleFocus(item: typeof props) {
  emit('focusItem', item);
}

function millisecondsToMinutesAndSeconds(milliseconds: number) {
  // Convert milliseconds to seconds
  const seconds = Math.floor(milliseconds / 1000);

  // Extract minutes and seconds
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // Format the result
  const result =
    minutes +
    ':' +
    (remainingSeconds > 9 ? remainingSeconds : '0' + remainingSeconds) +
    ' MIN';
  return result;
}

onBeforeMount(async () => {
  imgSrc.value = await getThumbnail({
    url: props.thumbnail.url,
    accessToken: props.accessToken,
    tenantName: props.tenantName,
  });
});
onMounted(() => {
  if (imgEl.value) {
    imgEl.value.src = imgSrc.value;
  }
});
</script>

<template>
  <div class="asset-list-item" @click="handleFocus(props)">
    <div class="thumbnail-wrapper">
      <img :src="imgSrc" class="thumbnail" ref="imgEl" alt="thumbnail" />
      <div class="absolute inset-0 flex items-center justify-center">
        <div class="w-10">
          <img class="w-10" :src="PlayIcon" alt="play" />
        </div>
      </div>
    </div>
    <div class="text-details">
      <!--      <span>-->
      <!--        {{new Date(audit.createdAtUtc).toLocaleDateString('de-de')}},-->
      <!--      </span>-->
      <span v-if="file.durationInMilliseconds">
        {{ millisecondsToMinutesAndSeconds(file.durationInMilliseconds) }}
      </span>
      <!--      <span>-->
      <!--        {{ usage.views }} Ansichten-->
      <!--      </span>-->
    </div>
    <h5 class="list-item-title">{{ name }}</h5>
  </div>
</template>

<style scoped>
.asset-list-item {
  @apply my-4 3xl:my-10  flex-[0_0_200px] h-[186px] cursor-pointer 3xl:h-[236px] 3xl:flex-[0_0_250px];

  .thumbnail-wrapper {
    @apply relative;
  }

  .thumbnail {
    @apply w-[200px] h-[112.5px] 3xl:w-[250px] 3xl:h-[140px];
  }

  .text-details {
    @apply my-2 3xl:my-3 text-xs;
  }

  .list-item-title {
    @apply text-base 3xl:text-xl w-[200px] 3xl:w-[250px] line-clamp-2;
  }
}
</style>
